import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackBar(props) {
  const { open, onClose, severity, message } = props;

  return (
    <Snackbar
      open={open}
      autoHideDuration={10000}
      onClose={onClose}
      anchorOrigin={{ vertical: "center", horizontal: "center" }}
      sx={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width: "fit-content",
        maxWidth: "90%",
      }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{ whiteSpace: "pre-wrap" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
