const COLOR = {
  GP_BLUE: "#1F75FE",
  WHITE: "#ffffff",
  BLACK: "#000000",
  LIGHT_TEXT: "#696969",
  DARK_BLUE: "#3F56B4",
  MEDIUM_BLUE: "#4E6BE2",
  LIGHT_BLUE: "#607DFD",
  TOP_CIRCLE: "#5570E7",
  TOP_GRADIENT: "#5975F2",
  YELLOW_TOP_GRADIENT: "#EFBA00",
  YELLOW_BOTTOM_GRADIENT: "#FFECA8",
  BOTTOM_GRADIENT: "#163070",
  PURPLE: "#742983",
  GREEN: "#06C903",
  RED: "#ff0000",
  ORANGE: "#FFA500",
  YELLOW: "#EFBA00",
  BLUE: "#5975F2",
  GREY: "#696969",
  GREEN_NEW: "#04B101",
  LIGHT_GREY: "#CACFDB",
};

export default COLOR;
