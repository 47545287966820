import * as React from "react";
import RouteList from "../src/routes/index.js";

export default function App() {
  return (
    <>
      <RouteList />
    </>
  );
}
